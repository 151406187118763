<template>
	<v-content class="stamp">
	
    <div class="body-2 pa-2 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">過去の来店予定メモ一覧</div>
		<div class="pa-3" v-if="items">

      <div class="" v-for="item in items" :key="item.id">
        <v-row no-gutters>
          <v-col cols="3" class="fontsize11 mb-3" style="color: #9D9D9D; line-height: 22px;">来店予定日</v-col>
          <v-col cols="9" class="fontsize13 mb-3" style="color: #333333; line-height: 22px;">{{item.memo_date}}</v-col>

          <v-col cols="3" class="fontsize11 mb-3" style="color: #9D9D9D; line-height: 22px;">時間</v-col>
          <v-col cols="9" class="fontsize13 mb-3" style="color: #333333; line-height: 22px;">{{item.memo_time}}</v-col>

          <v-col cols="3" class="fontsize11 mb-3" style="color: #9D9D9D; line-height: 22px;">メモ</v-col>
          <v-col cols="9" class="fontsize13 mb-3" style="color: #333333; line-height: 22px;">{{item.memo}}</v-col>
        </v-row>

        <v-divider class="mb-3"></v-divider>
      </div>

		</div>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
			<div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
			<div slot="no-more"></div>
			<div slot="no-results" class="pa-3 body-2">メモはありません</div>
		</infinite-loading>
	</v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default {
	components: {
    InfiniteLoading,
	},
	data: () => ({
    init: [],
    page: 1,
		items: null,
  }),
  async created () {
    this.init = this.storageGet('*')
  },
	methods: {
    async infiniteHandler() {
        var shop_id = this.getUrlGetParam('shop_id');
        var stamp_id = this.getUrlGetParam('stamp_id');

        var params = {
            'sid': this.init.sid,
            'device_uid': this.init.device_uid,
            'device_id': this.init.device_id,
            'os': this.init.os,
            'key': this.init.key,
            'page': this.page,
            'stamp_id': stamp_id
        } 

        if (shop_id) {
            params['shop_id'] = shop_id
        }

        var reqest_params = { params: params}

        var res = await this.apiCall('/stamp/memo-list', reqest_params)

        if (this.page == 1) {
            this.$set(this, 'items', res.data)
        } else {
            this.items = this.items.concat(res.data);
        }

        this.page = this.page + 1

        if (res.data && res.data.length > 0) {
            this.$refs.infiniteLoading.stateChanger.loaded()
        } else {
            this.$refs.infiniteLoading.stateChanger.complete()
        }
    },
	}
}
</script>

<style scoped>
</style>